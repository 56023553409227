export const palette = {
  primary: {
    normal: '#7353C8',
    light: '#EB71FF'
  },
  ink: {
    dark: '#223558',
    normal: '#5F7597',
    light: '#BFCCE1'
  },
  background: {
    normal: '#F3F7FD',
    light: '#FBF9FF'
  },
  border: {
    light: '#EAEAF8'
  },

  // Colors
  orange: {
    normal: '#FFB555',
    light: '#FFF3CC'
  },
  green: {
    normal: '#36B9A3',
    light: '#D4F8F2'
  },
  blue: {
    normal: '#00D2FF',
    light: '#CCF6FF'
  },
  red: {
    normal: '#FF6584',
    light: '#FFF2F3'
  },
  gray: {
    normal: '#777777',
    light: '#EFEFEF'
  }
};

export const size = {
  radius: {
    normal: '20px',
    tiny: '3px',
    small: '5px',
    medium: '15px',
    round: '999em'
  },
  gutter: {
    base: '2rem'
    // normal: '1rem',
    // small: '.5rem',
    // medium: '2rem',
    // large: '3rem'
  },
  maxMainWidth: '1024px'
};

export const font = {
  base: {
    size: '1.5rem',
    weight: 'normal',
    lineHeight: 1.6
  },
  size: {
    small: '1.3rem',
    regular: '1.5rem',
    large: '2.4rem'
  },
  title: {
    size: '2.4rem',
    weight: '700'
  },
  subtitle: {
    size: '1.8rem',
    weight: 'normal'
  },
  helpertext: {
    size: '1.3rem',
    weight: 'normal'
  }
};

// https://getbootstrap.com/docs/5.0/layout/breakpoints/
export const breaks = {
  tablet: '768px',
  desktop: '992px'
};

export const fontFamily = {
  serif:
    '"YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "YuMincho", "MS PMincho", serif'
};
