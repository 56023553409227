import styled from 'styled-components';
import { breaks, size } from './foundation';

export const UiPage = styled.main`
  max-width: ${size.maxMainWidth};
  margin: 0 auto;

  @media (min-width: ${breaks.desktop}) {
    margin-top: 50px;
  }
`;
