import React from 'react';
import styled from 'styled-components';

interface Props {
  as?: 'div' | 'section';
  children?: JSX.Element | JSX.Element[] | string;
  rest?: any;
}

export const UiContainer = styled(
  ({ as: Component = 'div', children, ...rest }: Props) => {
    return <Component {...rest}>{children}</Component>;
  }
)``;
