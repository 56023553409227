import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import ErrorPage from '../pages/error/ErrorPage';
// import PageMap from './pages/PageMap';
import SideBar from '../components/SideBar';
import Footer from '../components/Footer';
import * as r from '../services/routing';
import Header from '../components/Header';
import {
  UiLayout,
  UiLayoutContent,
  UiLayoutFooter,
  UiLayoutHeader,
  UiLayoutMain,
  UiLayoutSidebar
} from './styles';

// Lazy load pages
const HomePage = lazy(() => import('../pages/home'));
const ArticlePage = lazy(() => import('../pages/article'));
const ArticlesPage = lazy(() => import('../pages/articles'));
// const CreatorPage = lazy(() => import('../pages/CreatorPage'));
// const CreatorsPage = lazy(() => import('../pages/CreatorsPage'));
const StudioPage = lazy(() => import('../pages/studio'));
const StudiosPage = lazy(() => import('../pages/studios'));

const UiPageLoader = styled.div``;

// Component
const Layout = () => {
  // let location = useLocation();
  // React.useEffect(() => {
  //   console.log(location);
  // }, [location]);

  return (
    <UiLayout>
      <UiLayoutSidebar>
        <SideBar />
      </UiLayoutSidebar>
      <UiLayoutMain>
        <UiLayoutHeader>
          <Header />
        </UiLayoutHeader>
        <UiLayoutContent>
          <Suspense fallback={<UiPageLoader>ローディング...</UiPageLoader>}>
            <Routes>
              {/* <Route path="/pagemap" element={<PageMap />} /> */}
              <Route path={r.articlePath(':id')} element={<ArticlePage />} />
              <Route
                path={r.articlesPath(':page')}
                element={<ArticlesPage />}
              />
              <Route path={r.articlesPath()} element={<ArticlesPage />} />
              {/* <Route path={r.creatorPath(':id')} element={<CreatorPage />} />
            <Route path={r.creatorsPath(':page')} element={<CreatorsPage />} />
            <Route path={r.creatorsPath()} element={<CreatorsPage />} /> */}
              <Route path="/s/page/:page" element={<StudiosPage />} />
              <Route path={r.studioPath(':id')} element={<StudioPage />} />
              <Route path="/s" element={<StudiosPage />} />
              <Route path={r.rootPath()} element={<HomePage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </UiLayoutContent>
        <UiLayoutFooter>
          <Footer />
        </UiLayoutFooter>
      </UiLayoutMain>
    </UiLayout>
  );
};

export default Layout;
