// 閉じるスラッシュはつけない

/** Phase1 */
export const rootPath = () => '/';

// アーティクル
export const articlePath = (id: string) => `/a/${id}`;
export const articlesPath = (page?: number | ':page') => (page ? `/a/page/${page}` : '/a');
export const articlesSearchPath = ({ page }: { page?: number }) => {
  let path = '/a';
  page && page > 1 && (path += `/page/${page}`);
  return path;
};

// スタジオ詳細
export const studioPath = (id: string) => `/s/${id}`;
export const studioInterviewPath = (studioId: string, id: string) => `/s/${studioId}/i/${id}`;
export const studioPostsPath = (studioId: string, page?: number | ':page') => (page ? `/s/${studioId}/p/page/${page}` : `/s/${studioId}/p`);
export const studioPostPath = (studioId: string, id: string) => `/s/${studioId}/p/${id}`;
export const studioTiersPath = (studioId: string) => `/s/${studioId}/t`;

// スタジオ一覧
export const studiosPath = (page?: number | ':page') => (page ? `/s/page/${page}` : '/s');
export const studiosAreaPath = (areaId: number | ':areaId', page?: string) => (page ? `/s/a/${areaId}page/${page}` : `/s/a/${areaId}`);
export const studiosGenrePath = (genreId: string, page?: string) => (page ? `/s/g/${genreId}/page/${page}` : `/s/g/${genreId}`);
export const studiosAreaGenrePath = (areaId: number | ':areaId', genreId: string, page?: string) => (page ? `/s/a/${areaId}/g/${genreId}page/${page}` : `/s/a/${areaId}/g/${genreId}`);

// スタジオ一覧 - 検索
export const studiosSearchPath = ({ page, term }: { page?: number; term?: string }) => {
  let path = '/s';
  page && page > 1 && (path += `/page/${page}`);
  term && (path += `?term=${term}`);
  return path;
};
/** Phase2 */

// クリエイター
// export const creatorPath = (id: string) => `/c/${id}`;
// export const creatorsPath = () => `/c`;
// export const creatorTiersPath = (creatorId: string) => `/c/${creatorId}/tiers`;
// export const creatorPostPath = (creatorId: string, id: string) => `/c/${creatorId}/posts/${id}`;
// export const creatorPostsPath = (creatorId: string, page?: string) => (page ? `/c/${creatorId}/posts/page/${page}` : `/c/${creatorId}/posts`);
// export const invitationPath = (invitationToken: string, token: string) => `/invitation/${token}/${invitationToken}`;
