import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { UiGlobalStyles } from './styles/';
import Layout from './Layout';

const App = () => {
  return (
    <BrowserRouter>
      <UiGlobalStyles />
      <Layout />
      {process.env.NODE_ENV === 'test' && <div data-testid="app" />}
    </BrowserRouter>
  );
};

export default App;
