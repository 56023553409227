import styled, { css } from 'styled-components';
import { UiGeo, UiGeoProps } from './UiGeo';

export interface UiBoxProps extends UiGeoProps {
  textAlign?: 'center';
  bgWhite?: true;
  overflowHidden?: true;
  boxShadow?: true | string;
  borderRadius?: true;
}

export const UiBox = styled(UiGeo)<UiBoxProps>`
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ bgWhite }) =>
    bgWhite &&
    css`
      background-color: white;
    `}

${({ overflowHidden }) =>
    overflowHidden &&
    css`
      overflow: hidden;
    `}

  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: ${boxShadow === true
        ? '0 2px 10px rgba(34, 53, 88, 0.07)'
        : boxShadow};
    `}

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius === true ? '5px' : borderRadius};
    `}
`;
