import { createGlobalStyle } from 'styled-components';
import { font, palette } from './foundation';

export const UiGlobalStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  html {
    font-family: 'Helvetica Neue', Arial,
      'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo,
      sans-serif;

    /* デフォルトを10pxに変更 */
    font-size: 62.5%;
  }

  body {
    font-size: ${font.base.size};
    line-height: ${font.base.lineHeight};
    margin: 0;
    background-color: ${palette.background.normal};
    color: ${palette.ink.normal};
  }

  h1, h2, h3, h4 {
    font-size: ${font.base.size};
    font-weight: normal;
    line-height: ${font.base.lineHeight};
    margin: 0;
  }

  a {
    color: ${palette.primary.normal};
    text-decoration: none;
  }

  input {
    color: ${palette.ink.normal};
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  * {
    box-sizing: border-box;
  }

`;
