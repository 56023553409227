import styled from 'styled-components';
import { size } from './foundation';

export const UikBreadcrumbs = styled.div`
  display: flex;

  & > *:not(:last-child) {
    padding-right: ${size.gutter.base};
  }
`;
