import styled from 'styled-components';
import { breaks, size } from '../styles/foundation';

//#region Styles
export const UiLayout = styled.div`
  display: flex;
  min-height: 100%;
`;
export const UiLayoutSidebar = styled.div`
  background-color: #fff;
  width: 300px;
  display: none;

  @media (min-width: ${breaks.desktop}) {
    display: block;
  }
`;
export const UiLayoutMain = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;
export const UiLayoutHeader = styled.div`
  padding: ${size.gutter.base};

  @media (min-width: ${breaks.desktop}) {
    padding: 0;
  }
`;
export const UiLayoutContent = styled.div`
  flex: 1;
  margin-bottom: 70px;
`;
export const UiLayoutFooter = styled.div``;
//#endregion
