import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as r from '../../services/routing';
// import { slide as Menu } from 'react-burger-menu';
import { palette } from '../../styles/foundation';
import { UiLink } from '../../styles';

const UiSidebarLogo = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`;
const UiSidebarContent = styled.div`
  & > div:not(:last-child) {
    border-bottom: 1px solid ${palette.border.light};
  }
`;
const UiSidebarItem = styled.div`
  padding: 30px 20px 40px;
`;

const Sidebar = () => {
  return (
    <>
      <UiSidebarLogo>
        <SidebarLogo />
      </UiSidebarLogo>
      <UiSidebarContent>
        <UiSidebarItem>
          <SidebarItem
            title="ヨガスタジオを探す"
            links={[[r.studiosPath(), '一覧から探す']]}
          />
        </UiSidebarItem>
        <UiSidebarItem>
          <SidebarItem
            title="ヨガを学ぶ"
            links={[[r.articlesPath(), '記事一覧']]}
          />
        </UiSidebarItem>
      </UiSidebarContent>
      {process.env.NODE_ENV === 'test' && <div data-testid="NavBar" />}
    </>
  );
};

const UiSidebarItemTitle = styled.div`
  font-weight: bold;
  color: ${palette.ink.dark};
  margin-bottom: 5px;
`;

const SidebarItem = ({
  title,
  links
}: {
  title: string;
  links: [string, string][];
}) => {
  return (
    <>
      <UiSidebarItemTitle>{title}</UiSidebarItemTitle>
      {links.map(([path, title]) => (
        <UiLink to={path} key={`side-${title}`}>
          {title}
        </UiLink>
      ))}
    </>
  );
};

const UiSidebarItemLink = styled(Link)`
  color: ${palette.ink.dark};
  font-size: 4rem;
  display: block;
  font-family: 'Berkshire Swash', cursive;
  text-align: center;
`;

const SidebarLogo = () => {
  return (
    <>
      <UiSidebarItemLink to="/">Syumily</UiSidebarItemLink>
    </>
  );
};

export default Sidebar;
