import styled, { css } from 'styled-components';
import { size } from './foundation';

interface Props {
  horizontal?: true;
  gap?: string | true;
}

export const UiStack = styled.div<Props>`
  display: flex;

  ${({ gap }) =>
    gap
      ? css`
          gap: ${gap === true ? size.gutter.base : gap};
        `
      : css``}

  ${({ horizontal }) =>
    horizontal
      ? css`
          flex-wrap: wrap;
        `
      : css`
          flex-direction: column;
        `}
`;
