import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { palette } from './foundation';

export interface UiButtonLinkProps {
  color: 'primary';
}

export const UiButtonLink = styled(Link)<UiButtonLinkProps>`
  border-radius: 4rem;
  display: inline-block;
  line-height: 2rem;
  padding: 2rem 3rem;

  ${({ color }) =>
    color === 'primary' &&
    css`
      background-color: ${palette.primary.normal};
      color: #fff;
    `}
`;
