import styled, { css } from 'styled-components';
import { size } from './foundation';

export interface UiGeoProps {
  // ma?: number | true;
  // mh?: number | true;
  mv?: number | true;
  mt?: number | true;
  mb?: number | true;
  mh?: number | true | 'auto';
  // ml?: number | true;
  // mr?: number | true;
  // pa?: number | true;
  // ph?: number | true;
  // pv?: number | true;
  // pt?: number | true;
  pb?: number | true;
  // pl?: number | true;
  // pr?: number | true;
  row?: true;
  maxWidth?: number;
}

export const UiGeo = styled.div<UiGeoProps>`
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb === true ? size.gutter.base : mb + 'rem'};
    `}

  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt === true ? size.gutter.base : mt + 'rem'};
      margin-bottom: ${mt === true ? size.gutter.base : mt + 'rem'};
    `}

  ${({ mh }) => {
    if (mh === 'auto') {
      return css`
        margin-left: auto;
        margin-right: auto;
      `;
    } else {
      return css`
        margin-left: ${mh === true ? size.gutter.base : mh + 'rem'};
        margin-right: ${mh === true ? size.gutter.base : mh + 'rem'};
      `;
    }
  }}

  ${({ mv }) =>
    mv &&
    css`
      margin-top: ${mv === true ? size.gutter.base : mv + 'rem'};
      margin-bottom: ${mv === true ? size.gutter.base : mv + 'rem'};
    `}

  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: ${pb === true ? size.gutter.base : pb + 'rem'};
    `}

  /* gutter */
  ${({ row }) =>
    row &&
    css`
      margin-left: ${size.gutter.base};
      margin-right: ${size.gutter.base};
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
`;
