import React from 'react';
import styled from 'styled-components';

interface Props {
  as?: 'button' | 'a';
  children?: JSX.Element | JSX.Element[] | string;
  rest?: any;
}

export const UiButton = styled(
  ({ as: Component = 'button', children, ...rest }: Props) => {
    return <Component {...rest}>{children}</Component>;
  }
)``;
