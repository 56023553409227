import styled, { css } from 'styled-components';
import { breaks, palette } from './foundation';

export interface UiImageComponentProps {
  src: string;
  srcSet?: { src1x: string; src2x: string };
  alt: string;
  limitImageHeight?: true;
}

export interface UiImageProps {
  display?: 'block';
}

export const UiImage = styled(
  ({ src, srcSet, alt, ...rest }: UiImageComponentProps) => {
    let attributes: { srcSet?: string } = {};
    if (srcSet) {
      attributes['srcSet'] = `${srcSet.src1x} 1x, ${srcSet.src1x} 2x`;
    }

    return (
      <picture {...rest}>
        <img src={src} alt={alt} {...attributes} />
      </picture>
    );
  }
)<UiImageProps>`
  background-color: ${palette.ink.light};

  ${({ display }) =>
    display &&
    css`
      display: ${display};

      img {
        margin: 0 auto;
      }
    `}

  ${({ limitImageHeight }) =>
    limitImageHeight &&
    css`
      img {
        max-height: 120px;
      }
      @media (min-width: ${breaks.desktop}) {
        img {
          max-height: 200px;
        }
      }
    `}
`;
