import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import TagManager from 'react-gtm-module';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { getIdToken } from './services/firebase';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_HOST
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const idToken = await getIdToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + idToken
    }
  };
});

// Error handling
// https://www.apollographql.com/docs/react/api/link/introduction/
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: authLink.concat(from([errorLink, httpLink])),
  cache: new InMemoryCache()
});

// ReactGA.initialize('UA-103060969-1');
TagManager.initialize({ gtmId: 'GTM-KG8B4VC' });

const rootDiv = document.getElementById('root');
if (!rootDiv) throw new Error('The element #root was not found');
const root = createRoot(rootDiv);
root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
