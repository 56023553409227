import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { font, palette } from '../../styles/foundation';

const UiFooter = styled.footer`
  background-color: ${palette.ink.dark};
  text-align: center;
  padding: 20px;
`;

const UiFooterLogo = styled.div`
  margin-bottom: 15px;
`;

const UiFooterLogoLink = styled(Link)`
  color: #fff;
  font-size: ${font.size.large};
  font-family: 'Berkshire Swash', cursive;
`;

const UiFooterLinks = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${font.size.small};

  a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  & > a:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Footer = () => (
  <>
    <UiFooter>
      <UiFooterLogo>
        <UiFooterLogoLink to="/">Syumily</UiFooterLogoLink>
      </UiFooterLogo>
      <UiFooterLinks>
        <a href="https://localgraph.co" target="_blank" rel="noreferrer">
          会社概要
        </a>
        <a
          href="https://localgraph.co/about/privacy"
          target="_blank"
          rel="noreferrer"
        >
          プライバシーポリシー
        </a>
        <a href="https://localgraph.co" target="_blank" rel="noreferrer">
          利用規約
        </a>
      </UiFooterLinks>
    </UiFooter>
    {process.env.NODE_ENV === 'test' && <div data-testid="Footer" />}
  </>
);

export default Footer;
