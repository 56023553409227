import React from 'react';
import styled, { css } from 'styled-components';
import { palette, size } from './foundation';

export interface UiHeadingComponentProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children?: JSX.Element | JSX.Element[] | string;
  rest?: any;
}

export interface UiHeadingProps {
  textAlign?: 'center';
  mb?: true;
}

/** テキストを制御します */
export const UiHeading = styled(
  ({ as: Component, children, ...rest }: UiHeadingComponentProps) => {
    return <Component {...rest}>{children}</Component>;
  }
)<UiHeadingProps>`
  font-weight: bold;
  color: ${palette.ink.dark};

  ${({ as }) => {
    switch (as) {
      case 'h1':
        return css`
          font-size: 2.4rem;
        `;
      case 'h2':
        return css`
          font-size: 1.8rem;
        `;
      default:
        return css`
          font-size: 1.5rem;
        `;
    }
  }}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${size.gutter.base};
    `};
`;
