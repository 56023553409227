import React from 'react';
import styled from 'styled-components';

const UiNotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ErrorPage = () => (
  <>
    <UiNotFound>ページが見つかりません</UiNotFound>
    {process.env.NODE_ENV === 'test' && <div data-testid="ErrorPage" />}
  </>
);

export default ErrorPage;
