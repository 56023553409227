import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette, breaks } from '../../styles/foundation';
import Menu from '../Menu';

const UiHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

const UiHeaderLogo = styled(Link)`
  color: ${palette.ink.dark};
  font-size: 2.8rem;
  font-family: 'Berkshire Swash', cursive;

  @media (min-width: ${breaks.desktop}) {
    display: none;
  }
`;

const UiHeaderMenu = styled.div``;

const Header = () => (
  <UiHeader>
    <UiHeaderLogo to="/">Syumily</UiHeaderLogo>
    <UiHeaderMenu>
      <Menu />
    </UiHeaderMenu>
  </UiHeader>
);

export default Header;
