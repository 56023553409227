import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  GoogleAuthProvider
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
};

// Initialize firebase
initializeApp(firebaseConfig);

// Fireauth
export const firebaseAuth = getAuth();

// Provider
const provider = new GoogleAuthProvider();

// Sign in
export const signInWithGoogle = (
  e: React.MouseEvent<HTMLButtonElement>
): void => {
  e.preventDefault();
  signInWithPopup(firebaseAuth, provider);
};

// Sign out
export const firebaseSignOut = (
  e: React.MouseEvent<HTMLButtonElement>
): void => {
  e.preventDefault();
  signOut(firebaseAuth)
    .then(() => {
      // Sign-out successful.
    })
    .catch(error => {
      // An error happened.
    });
};

// https://firebase.google.com/docs/auth/web/service-worker-sessions
export const getIdToken = (): Promise<any> => {
  return new Promise((resolve, reject): any => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user): any => {
      unsubscribe();
      if (user) {
        user.getIdToken().then(
          (idToken: any): any => {
            resolve(idToken);
          },
          (error: any): any => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  });
};
