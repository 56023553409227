import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
// import { palette } from './foundation';

export interface UiLinkProps extends LinkProps {
  // color?: 'ink';
  // display?: 'block';
}

export const UiLink = styled(Link)<UiLinkProps>`
  &:hover {
    opacity: 0.8;
  }
`;
